import { useRoute, createRouter, createWebHistory } from 'vue-router';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import Home from '../views/index.vue';
import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    //dashboard
    // { path: '/', name: 'Home', component: Home },

    // {
    //     path: '/',
    //     name: 'Home',
    //     component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue'),
    // },

    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/empresas/lista.vue'),
        meta: { layout: 'empresas', requiresAuth: true },
    },

    //empresas
    {
        path: '/empresa/lista',
        name: 'empresa-lista',
        component: () => import(/* webpackChunkName: "apps-contacts" */ '../views/empresas/lista.vue'),
        meta: { layout: 'empresas', requiresAuth: true },
    },

    {
        path: '/empresa/:id',
        name: 'empresa-selecionar',
        component: () => import(/* webpackChunkName: "apps-contacts" */ '../views/empresas/lista.vue'),
        meta: { layout: 'empresas', requiresAuth: true },
    },


    //relatórios
    {
        path: '/relatorios/registros/:id',
        name: 'relatorios-registros',
        component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/relatorios/registros.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/relatorios/ftds/:id',
        name: 'relatorios-ftds',
        component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/relatorios/ftds.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/relatorios/ftds/test/:id',
        name: 'relatorios-ftds-test',
        component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/relatorios/ftds_test.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/relatorios/horarios/:id',
        name: 'relatorios-horarios',
        component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/relatorios/horarios.vue'),
        meta: { requiresAuth: true },
    },

    //auth
    {
        path: '/auth/login-boxed',
        name: 'login-boxed',
        component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/register-boxed',
        name: 'register-boxed',
        component: () => import(/* webpackChunkName: "auth-register-boxed" */ '../views/auth/register_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/lockscreen-boxed',
        name: 'lockscreen-boxed',
        component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/auth/lockscreen_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/pass-recovery-boxed',
        name: 'pass-recovery-boxed',
        component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/auth/pass_recovery_boxed.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/cadastro',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/lockscreen',
        name: 'lockscreen',
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/auth/lockscreen.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/pass-recovery',
        name: 'pass-recovery',
        component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
        meta: { layout: 'auth' },
    },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

// router.beforeEach((to, from, next) => {
//     if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
//         store.commit('setLayout', 'auth');
//     } else if (to.meta && to.meta.layout && to.meta.layout == 'empresas') {
//         store.commit('setLayout', 'empresas');
//     } else {
//         store.commit('setLayout', 'app');
//     }
//     next(true);
// });

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = isUserLoggedIn();  // Utiliza a função para verificar se o usuário está logado

    // Verifica se a rota requer uma configuração de layout específica
    if (to.meta && to.meta.layout) {
        if (to.meta.layout === 'auth') {
            store.commit('setLayout', 'auth');
        } else if (to.meta.layout === 'empresas') {
            store.commit('setLayout', 'empresas');
        } else {
            store.commit('setLayout', 'app');
        }
    } else {
        store.commit('setLayout', 'app');  // Configuração padrão do layout
    }

    // Redireciona para a página inicial se acessar '/' e estiver logado
    if (to.path === '/' && isLoggedIn) {
        return next({ name: 'empresa-lista' });  // Assume que 'Home' é o nome da rota inicial para usuários logados
    }

    // Verifica se a rota exige autenticação e o usuário não está logado
    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        localStorage.setItem('originalUrl', to.fullPath);  // Armazena a URL original para redirecionar após o login
        return next({ name: 'login' });  // Direciona para a rota de login
    }

    return next();  // Continua para a rota desejada se estiver tudo ok
});



export default router;
