<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        
        <nav ref="menu" id="sidebar">
            <!-- <div class="shadow-bottom"></div> -->

            <div class="mt-4 pe-3">
                <multiselect v-model="empresaSelecionada" placeholder="Selecione" label="name" track-by="id" :options="empresasOptions" :allowEmpty="false"
                        :option-height="30" :show-labels="false">

                    <template #singleLabel="props">
                        <img class="option__image" :src="`${this.jwtConfig.storageImageEndpoint}/${props.option.image}`"/>
                        <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                        </span>
                    </template>

                    <template #option="props">
                        <img class="option__image" :src="`${this.jwtConfig.storageImageEndpoint}/${props.option.image}`"/>
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                        </div>
                    </template>

                </multiselect>
            </div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                
                <!-- <li class="menu">
                    <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-home"
                            >
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span>Dashboard</span>
                        </div>
                    </router-link>
                </li> -->

                <li class="menu">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#datatables" aria-controls="datatables" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-layers"
                            >
                                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                <polyline points="2 17 12 22 22 17"></polyline>
                                <polyline points="2 12 12 17 22 12"></polyline>
                            </svg>
                            <span>Relatórios</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="datatables" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link :to="{ name: 'relatorios-registros', params: { id: `${$route.params.id}` } }" @click="toggleMobileMenu">Registros</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'relatorios-ftds', params: { id: `${$route.params.id}` } }" @click="toggleMobileMenu">FTDs</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'relatorios-ftds-test', params: { id: `${$route.params.id}` } }" @click="toggleMobileMenu">FTDs Test</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'relatorios-horarios', params: { id: `${$route.params.id}` } }" @click="toggleMobileMenu">Best Time</router-link>
                        </li>
                    </ul>
                </li>

                
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import Multiselect from '@suadelabs/vue3-multiselect';
import useJwt from '@/auth/jwt/useJwt'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';


export default {
  
  data() {
    return {
      menuCollapse: 'dashboard',
      empresasOptions: [],
      empresaSelecionada: null,
      carregandoApi: false,
      jwtConfig: { ...jwtDefaultConfig },
    };
  },
  components: {
    Multiselect,
  },
  mounted() {
    const store = useStore();
    const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    if (selector) {
      const ul = selector.closest('ul.collapse');
      if (ul) {
        let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          }, 0);
        }
      } else {
        selector.click();
      }
    }

    const toggleMobileMenu = () => {
      if (window.innerWidth < 991) {
        store.commit('toggleSideBar', !store.state.is_show_sidebar);
      }
    };
    // Since toggleMobileMenu is a method, it needs to be called within the component's methods object
    this.toggleMobileMenu = toggleMobileMenu;

    // Verifica se o usuário está logado antes de carregar os dados das empresas
    if (isUserLoggedIn()) {
      this.dadosEmpresas();

      if (this.$route.params.id) {
        this.updateSelectedEmpresa(this.$route.params.id);
      }
    }

  },
  watch: {

    empresaSelecionada(newVal) {
      // Checa se o novo valor é diferente de null e se o ID é diferente do ID atual na rota
      if (newVal && this.$route.params.id !== newVal.id.toString()) {
        // this.$router.push({ name: 'relatorios-registros', params: { id: newVal.id } });
        window.location.href = `${this.$router.resolve({ name: 'relatorios-registros', params: { id: newVal.id } }).href}`;
      }
    }

  },
  methods: {
    // toggleMobileMenu() {
    //   // This will be overridden in mounted()

    // },

    dadosEmpresas() {

        if (!isUserLoggedIn()) return;

        this.carregandoApi = true;

        useJwt.empresas({
            
        })
        .then(response => {

            this.empresasOptions = response.data.data

            // Atualize a seleção baseada no id da rota depois de carregar os dados
            if (this.$route.params.id) {
                this.updateSelectedEmpresa(this.$route.params.id);
            }

        })
        .catch(error => {

            new window.Swal({
                icon: 'error',
                title: 'Ops! Aconteceu um erro.',
                text: error.response.data.error,
                padding: '2em',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
            });

        })
        .finally(() => {
            this.carregandoApi = false
        })
        
    },

    updateSelectedEmpresa(id) {

      const matchedEmpresa = this.empresasOptions.find(empresa => empresa.id.toString() === id.toString());
      if (matchedEmpresa) {
        this.empresaSelecionada = matchedEmpresa;
      } else {
        this.empresaSelecionada = null;
      }
      
    }

  }
}
</script>

<style>
.option__image {
    max-height: 25px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
}

.option__desc {
    display: inline-block;
    vertical-align: middle;
    padding: rem(10px)
}

.option__title {
    font-size: 13px;
}

.option__small {
    margin-top: 10px;
    display: block
}

#sidebar * {
    overflow: revert !important;
}

/* .multiselect {
    overflow: unset !important;
} */



</style>